@font-face {
  font-family: NanumSquareB;
  src: url(./fonts/NanumSquareB.woff);
}

@font-face {
  font-family: NanumSquareR;
  src: url(./fonts/NanumSquareR.woff);
}

@font-face {
  font-family: NanumSquareL;
  src: url(./fonts/NanumSquareL.woff);
}
